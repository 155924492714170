import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Product, ProductCreateDTO, ProductWinner } from "interfaces/Product";
import { useAuthInterceptor } from "utils/AuthInterceptorProvider";
import { useSession } from "./SessionProvider";
import { isPastDate } from "utils/date.util";

const ProductContext = React.createContext<{
  productsList: Product[];
  selectedProduct: Product | null;
  addProduct: (productData: ProductCreateDTO) => Promise<Product | undefined>;
  editProduct: (id: string, productData: ProductCreateDTO) => Promise<Product | undefined>;
  getProducts: () => Promise<void>;
  getProductWinners: () => Promise<ProductWinner[] | undefined>;
  updateSelectedProduct: (productId?: string) => void;
  getProductDetail: (productId: string) => Product | undefined;
}>({
  productsList: [],
  selectedProduct: null,
  updateSelectedProduct: () => { },
  addProduct: () => { throw Error("Method not implemented") },
  editProduct: () => { throw Error("Method not implemented") },
  getProducts: () => { throw Error("Method not implemented") },
  getProductWinners: () => { throw Error("Method not implemented") },
  getProductDetail: () => { throw Error("Method not implemented") }

});

interface Props {
  children: React.ReactNode;
}

export const ProductProvider: React.FC<Props> = ({ children }) => {
  const { api } = useAuthInterceptor();
  const { token } = useSession();
  const [productsList, setProductsList] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const getProducts = useCallback(async () => {
    try {
      const { data } = await api.get<{ data: Product[] }>(
        "/auction/get-auction-items"
      );

      setProductsList(data.data);
    } catch (e) {
      console.log(e);
    }
  }, [api]);

  const getProductDetail = useCallback((productId: string) => {
    return productsList.find(product => product.id === productId);
  }, [productsList]);

  const getProductWinners = useCallback(async () => {
    try {
      const { data } = await api.get<{ data: ProductWinner[] }>(
        "/auction/get-auction-winners"
      );

      return data.data;
    } catch (e) {
      console.log(e);
    }
  }, [api]);

  const addProduct = useCallback(async (productData: ProductCreateDTO) => {
    try {
      const formData = new FormData();

      formData.append("name", productData.name);
      formData.append("description", productData.description);
      formData.append("minBidAmount", String(productData.minBidAmount));

      productData.images.forEach((image, index) => {
        formData.append(`image_${index + 1}`, image as Blob);
      })

      const { data } = await api.post<{ data: Product }>(
        "/auction/create-auction",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }
      );

      return data.data;
    } catch (e) {
      console.log(e);
    }
  }, [api]);

  const editProduct = useCallback(async (id: string, productData: ProductCreateDTO) => {
    try {
      const formData = new FormData();

      formData.append("name", productData.name);
      formData.append("description", productData.description);
      formData.append("minBidAmount", String(productData.minBidAmount));
      formData.append("bidStartTime", String(productData.bidStartTime));
      formData.append("bidEndTime", String(productData.bidEndTime));

      productData.images.forEach((image, index) => {
        formData.append(`image_${index + 1}`, image as Blob);
      })

      const { data } = await api.put<{ data: Product }>(
        `/auction/update-auction/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }
      );

      return data.data;
    } catch (e) {
      console.log(e);
    }
  }, [api]);

  const updateSelectedProduct = useCallback((productId?: string) => {
    if (!productId) {
      return setSelectedProduct(null);
    }

    setSelectedProduct(productsList.find(product => product.id === productId) || null);
  }, [productsList]);

  useEffect(() => {
    if (token) {
      getProducts();
    }
  }, [getProducts, token]);

  return (<ProductContext.Provider value={{ productsList, selectedProduct, getProductDetail, updateSelectedProduct, addProduct, editProduct, getProducts, getProductWinners }}>{children}</ProductContext.Provider>);
};

export function useProduct() {
  const productContext = useContext(ProductContext);

  const biddingEndDate = productContext.selectedProduct?.bidEndTime ? new Date(productContext.selectedProduct?.bidEndTime) : null;

  const searchProducts = useCallback((searchText: string, subset?: number) => {
    return productContext.productsList
      .filter(product => !isPastDate(product.bidEndTime))
      .filter(product => searchText
        ? product.name?.toLowerCase()?.includes(searchText.toLowerCase())
        : true
      ).slice(0, subset)
  }, [productContext.productsList]);

  return { biddingEndDate, searchProducts, ...productContext };
}
