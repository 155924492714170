import { HistoryItem } from "./HistoryItem"
import "./History.css"
import React from "react"
import { useBid } from "providers/BidProvider"

export const History: React.FC = () => {
  const { bids } = useBid();

  return (
    <section className="notify">
      {bids.map((bid, idx) => {
        return (
          <React.Fragment key={idx}>
            <HistoryItem
              product={bid.product}
              createdAt={bid.bidTime}
            />
          </React.Fragment>
        )
      })}
    </section>
  )
}
