import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { useSession } from "providers/SessionProvider"
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
} from "react-bootstrap"
import Splash from "components/splash/Splash"
import LogoHeading from "components/headers/logo-heading/LogoHeading"
import { routes } from "constants/routes"
import countries from "constants/countries.json"
import { LoginDTO } from "interfaces/Login"
import { isNotNumericKey } from "utils/input.util"
import { LoginSchema } from "./login.schema"
import "./Login.css"

interface Country {
  name: string
  dialCode: string
  isoCode: string
}

function getSelectedDropdownItem(country: Country) {
  return (
    <>
      <img
        className="me-1"
        src={`https://flagcdn.com/w20/${country.isoCode}.png`}
        srcSet={`https://flagcdn.com/w40/${country.isoCode}.png 2x`}
        width="20"
        alt={country.name}
      />
      <span>{country.dialCode}</span>
    </>
  )
}

const defaultCountry: Country = {
  name: "India",
  dialCode: "+91",
  isoCode: "in",
}

const Login: React.FC = () => {
  const navigate = useNavigate()
  const { generateOTP } = useSession()
  const [selectedCountry, setSelectedCountry] = useState<Country>(
    defaultCountry
  )
  const formik = useFormik<LoginDTO>({
    initialValues: {
      dialingCode: "+91",
      mobileNumber: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      generateOTP(values)
        .then(() => {
          navigate(routes.otp, {
            state: {
              mobileNumber: `${values.dialingCode} ${values.mobileNumber}`,
            },
          })
        })
        .catch((ex) => console.log(ex))
    },
  })

  function handleDialingCodeSelect(country: Country) {
    formik.setFieldValue("dialingCode", country.dialCode)
    setSelectedCountry(country)
  }

  if (formik.isSubmitting) {
    return <Splash />
  }

  return (
    <div className="login">
      <header>
        <LogoHeading />
      </header>
      <div className="my-5 pt-5">
        <h3>Login</h3>
        <p>Enter your phone number to create your account or login</p>
      </div>
      <Form className="login-form" onSubmit={formik.handleSubmit}>
        <Form.Group>
          <Form.Label htmlFor="mobileNumber">Phone Number</Form.Label>
          <InputGroup className="has-validation">
            <DropdownButton
              variant="outline-secondary"
              title={getSelectedDropdownItem(selectedCountry)}
              id="input-group-dropdown-1"
            >
              {countries.map((country) => (
                <Dropdown.Item
                  key={country.isoCode}
                  onClick={() => handleDialingCodeSelect(country)}
                >
                  {country.name} ({country.dialCode})
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <Form.Control
              id="mobileNumber"
              name="mobileNumber"
              type="text"
              placeholder="Enter phone number"
              onKeyDown={(e) => isNotNumericKey(e.key) && e.preventDefault()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mobileNumber}
              isInvalid={
                formik.touched.mobileNumber &&
                Boolean(formik.errors.mobileNumber)
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.mobileNumber}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <div>
          <Button type="submit" disabled={!formik.isValid}>
            Send OTP
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default Login
