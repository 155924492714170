import { useEffect, useState } from "react"
import { NavLink, Outlet, useLocation } from "react-router-dom"
import { routes } from "constants/routes"
import { headers } from "constants/headers"
import SlottedHeader, {
  SlottedHeaderProps,
} from "components/headers/slotted-header/SlottedHeader"

import { ReactComponent as HomeIcon } from "assets/icons/home.svg"
import { ReactComponent as BidIcon } from "assets/icons/bids.svg"
import { ReactComponent as UserIcon } from "assets/icons/user.svg"

import "./HomeWrapper.css"

const defaultHeaderProps: SlottedHeaderProps = {
  startSlot: null,
  title: "",
  endSlot: null,
}

const Home: React.FC = () => {
  const location = useLocation()
  const [header, setHeader] = useState<SlottedHeaderProps>(defaultHeaderProps)

  useEffect(() => {
    if (location.pathname) {
      const pathKey = location.pathname
        .split("/")
        .map((param) => (Number.parseInt(param) || param.length === 36 ? "*" : param))
        .join("/")

      setHeader(headers.get(pathKey) || defaultHeaderProps)
    }
  }, [location])

  return (
    <div className="home-wrapper">
      <SlottedHeader
        startSlot={header.startSlot}
        title={header.title}
        endSlot={header.endSlot}
      />
      <main>
        <Outlet />
      </main>
      <footer>
        <NavLink to={routes.home}>
          <HomeIcon className="mb-2" />
          <p>Home</p>
        </NavLink>
        <NavLink to={routes.bids}>
          <BidIcon className="mb-2" />
          <p>My Bids</p>
        </NavLink>
        <NavLink to={routes.profile}>
          <UserIcon className="mb-2" />
          <p>Profile</p>
        </NavLink>
      </footer>
    </div>
  )
}

export default Home
