import { useCallback, useEffect, useState } from "react";
import { formatDate } from "utils/date.util"
import { Bid } from "interfaces/Bid";
import { Product } from "interfaces/Product";

import { ReactComponent as TimerSvG } from "assets/icons/timer.svg"
import { differenceInDays, intervalToDuration, parseJSON } from "date-fns";
import { Countdown } from "interfaces/Countdown";
import "./ProductTimer.css";

interface ProductTimerProps {
    product: Product;
    userBid: Bid | undefined;
    totalBids: number;
}

const ProductTimer: React.FC<ProductTimerProps> = ({ product, userBid, totalBids }) => {
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const [countdown, setCountdown] = useState<Countdown>({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    const getCountdownString = useCallback(() => {
        return Object.entries(countdown).filter(duration => Boolean(duration[1])).reduce((acc, curr) => acc + curr[1] + curr[0][0] + " : ", "").slice(0, -3);
    }, [countdown]);

    useEffect(() => {
        const biddingEndDate = product.bidEndTime ? parseJSON(product.bidEndTime) : new Date();

        const countdownInterval = setInterval(() => {
            const now = new Date();

            if (now >= biddingEndDate) {
                clearInterval(countdownInterval);
                return setIsExpired(true);
            }

            const duration = intervalToDuration({ start: now, end: biddingEndDate });
            const daysLeft = differenceInDays(biddingEndDate, now);

            setCountdown(prevState => ({
                ...prevState,
                days: daysLeft,
                hours: duration.hours || 0,
                minutes: duration.minutes || 0,
                seconds: duration.seconds || 0
            }));

        }, 1000);

        return () => clearInterval(countdownInterval);
    }, [product]);

    return (
        <div className="product-details">
            <div className="expiry">
                <TimerSvG color="var(--bs-primary)" />
                {isExpired
                    ? (<p>Expired On: {formatDate(product.bidEndTime)}</p>)
                    : (<p>Time left to bid <span style={{ color: "var(--bs-primary)" }}>{getCountdownString()}</span></p>)
                }
            </div>
            <div className="product-info">
                {/* start product div */}
                <div className="start">
                    <h5>Starting Bid</h5>
                    <p>${product.minBidAmount}</p>
                </div>
                <div className="divider"></div>
                {/* own product div */}
                <div className="product-value">
                    <h5>Your Bid</h5>
                    <p>${userBid?.bidAmount || "NA"}</p>
                </div>
                <div className="divider"></div>
                {/* total product div */}
                <div className="total">
                    <h5>Total Bids</h5>
                    <p>{totalBids}</p>
                </div>
            </div>
        </div>
    )
}

export default ProductTimer;
