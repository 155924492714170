import { currencyFormatter } from "utils/number.util"
import { getImageSrc } from "utils/string.util"
import { Product } from "interfaces/Product"
import { useNavigate } from "react-router-dom"
import "./History.css";
import { routes } from "constants/routes";

interface HistoryItemProps {
  product: Product;
  createdAt: string;
}

export const HistoryItem: React.FC<HistoryItemProps> = ({ product, createdAt }) => {
  const navigate = useNavigate();

  function handleViewBids(productId: string) {
    navigate(routes.placeBid(productId))
  }

  return (
    <div className="product-item">
      <div>
        <img src={getImageSrc(product.image1)} alt={product.name} />
      </div>
      <div className="product-item-detail">
        <div>
          <h5>{product.name}</h5>
        </div>
        <div className="bid-section">
          <div>
            <p className="highest-bid">
              {currencyFormatter.format(0)}
            </p>
            <p className="highest-bid-label">
              Your Bid
            </p>
          </div>
          <p>
            {createdAt}
          </p>
        </div>
      </div>
    </div>
  )
}
