import { useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useProduct } from "providers/ProductProvider";
import { useBid } from "providers/BidProvider";
import { routes } from "constants/routes";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Bid, BidCreateDTO } from "interfaces/Bid";
import { PlaceBidSchema } from "./place-bid.schema";
import { integerCurrencyFormatter } from "utils/number.util";
import "./PlaceBid.css";

interface PlaceBidProps {
    userBid: Bid | undefined;
}

const PlaceBid: React.FC<PlaceBidProps> = ({ userBid }) => {
    const { productId } = useParams();
    const navigate = useNavigate();
    const { updateSelectedProduct, selectedProduct } = useProduct();
    const { placeBid } = useBid();
    const { setValues, ...formik } = useFormik<BidCreateDTO>({
        initialValues: {
            productId: "",
            minBidAmount: 0,
            bidAmount: 0
        },
        validationSchema: PlaceBidSchema,
        onSubmit: values => {
            placeBid(values)
                .then(res => {
                    if (res) {
                        navigate(routes.home);
                    }
                })
        }
    });

    const bidDifference = useMemo(() => formik.values.bidAmount - (userBid?.bidAmount || 0), [formik.values.bidAmount, userBid?.bidAmount]);

    useEffect(() => {
        if (productId) {
            updateSelectedProduct(productId);
        }

        return () => updateSelectedProduct();
    }, [productId, updateSelectedProduct]);

    useEffect(() => {
        if (selectedProduct) {
            setValues({
                productId: selectedProduct.id,
                minBidAmount: selectedProduct.minBidAmount,
                bidAmount: 0
            });
        }
    }, [selectedProduct, setValues]);

    return (
        <div className="place-bid">
            <Form onSubmit={formik.handleSubmit} noValidate>
                <Form.Group>
                    <Form.Label htmlFor="bid-amount">
                        Your Bid
                    </Form.Label>
                    <InputGroup>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control
                            id="bid-amount"
                            name="bidAmount"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bidAmount}
                            isInvalid={formik.touched.bidAmount && Boolean(formik.errors.bidAmount)}
                        />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.bidAmount}
                    </Form.Control.Feedback>
                    {bidDifference > 0 && (<p className="bid-difference text-end">
                        *Bid increased by {integerCurrencyFormatter.format(bidDifference)}
                    </p>)}
                </Form.Group>
                <Button type="submit" disabled={!formik.isValid && Boolean(formik.submitCount)}>
                    Submit
                </Button>
            </Form>
        </div>
    )
}

export default PlaceBid;
