import React from "react";
import BidListItem from "../bid-list-item/BidListItem";
import { Bid } from "interfaces/Bid";
import "./MyBidsList.css";

interface MyBidsListProps {
    filteredBids: Bid[];
}

const MyBidsList: React.FC<MyBidsListProps> = ({ filteredBids }) => {
    return (
        <div>
            {filteredBids.map(bid => <React.Fragment key={bid.id}>
                <BidListItem product={bid.product} bidId={bid.id} />
            </React.Fragment>)}
        </div>
    )
}

export default MyBidsList;
