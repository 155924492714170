import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { clearSessionData } from "services/session.service"
import { Row, Col } from "react-bootstrap"
import { routes } from "constants/routes"
import { LogoutModal } from "components/modals/LogoutModal/LogoutModal"
import { useFormik } from "formik"
import { ProfileSchema } from "./profile.schema"
import countries from "constants/countries.json"
import { isNotNumericKey } from "utils/input.util"
import { Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap"
import { UserEditDTO } from "interfaces/User"
import { useUser } from "providers/UserProvider"
import { useSession } from "providers/SessionProvider"
import "./Profile.css"
import MessageToast from "components/toasts/MessageToast"

interface Country {
  name: string
  dialCode: string
  isoCode: string
}

export const Profile: React.FC = () => {
  const { getUser, user } = useSession();
  const { updateUser } = useUser();
  const [selectedImage, setSelectedImage] = useState<
    string | ArrayBuffer | null
  >(null);
  const [responseMessage, setResponseMessage] = useState({ message: "", isSuccess: true });

  const defaultCountry: Country = {
    name: "India",
    dialCode: "+91",
    isoCode: "in",
  }

  const [isShown, setIsShown] = useState<boolean>(false)
  const [selectedCountry, setSelectedCountry] = useState<Country>(
    defaultCountry
  )

  const navigate = useNavigate()

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      formik.setFieldValue("profileImage", file);

      reader.onload = () => {
        setSelectedImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  function getSelectedDropdownItem(country: Country) {
    return (
      <>
        <img
          className="me-1"
          src={`https://flagcdn.com/w20/${country.isoCode}.png`}
          srcSet={`https://flagcdn.com/w40/${country.isoCode}.png 2x`}
          width="20"
          alt={country.name}
        />
        <span>{country.dialCode}</span>
      </>
    )
  }

  function handleDialingCodeSelect(country: Country) {
    formik.setFieldValue("dialingCode", country.dialCode)
    setSelectedCountry(country)
  }

  // Formik
  const { resetForm, ...formik } = useFormik<UserEditDTO>({
    initialValues: {
      mobileNumber: "",
      name: "",
      profileImage: null
    },
    validationSchema: ProfileSchema,
    onSubmit: (values) => {
      // API call
      console.log(values);
      updateUser(values)
        .then((res) => {
          if (res) {
            setResponseMessage({ message: "Profile updated successfully", isSuccess: true });
            getUser();
          }
        }).then(() => {
          setTimeout(() => {
            navigate(routes.home);
          }, 3000);
        })
        .catch((ex) => {
          setResponseMessage({ message: ex?.message, isSuccess: true });
        });
    },
  })

  useEffect(() => {
    if (user) {
      setSelectedImage(user.profileImage);
      resetForm({
        values: {
          mobileNumber: user.mobileNumber,
          name: user.name,
          profileImage: null
        }
      })
    }
  }, [user, resetForm]);

  return (
    <div className="profile">
      {/* user info */}

      <div className="user">
        <div className="profile">
          <Row>
            {/* image preview */}
            <Col xs={3}>
              {selectedImage ? (
                <img
                  src={selectedImage as string}
                  alt="Profile Pic"
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <div
                  onClick={() => document.getElementById("fileInput")?.click()}
                  style={{
                    width: "70px",
                    height: "70px",
                    backgroundColor: "#f0f0f0",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* Avatar */}
                  <span style={{ fontSize: "30px", color: "#999" }}>👤</span>
                </div>
              )}
            </Col>

            {/* text */}
            <Col xs={9} className="d-flex flex-column justify-content-center">
              <Form.Group>
                <input
                  type="file"
                  accept="image/*"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
                <p
                  className="photo"
                  onClick={() => document.getElementById("fileInput")?.click()}
                >
                  Upload Photo
                </p>
              </Form.Group>
            </Col>
          </Row>
        </div>

        <Form className="userForm" onSubmit={formik.handleSubmit}>
          {/* name div */}
          <Form.Group>
            <Form.Label htmlFor="name">Name</Form.Label>
            <InputGroup className="has-validation">
              <Form.Control
                id="name"
                name="name"
                type="text"
                placeholder="Enter your name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isInvalid={formik.touched.name && Boolean(formik.errors.name)}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          {/* mobile div */}
          <Form.Group>
            <Form.Label htmlFor="mobileNumber">Phone Number</Form.Label>
            <InputGroup className="has-validation">
              <DropdownButton
                variant="outline-secondary"
                title={getSelectedDropdownItem(selectedCountry)}
                className="mobileDropDown"
              >
                {countries.map((country) => (
                  <Dropdown.Item
                    key={country.isoCode}
                    onClick={() => handleDialingCodeSelect(country)}
                  >
                    {country.name} ({country.dialCode})
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <Form.Control
                id="mobileNumber"
                name="mobileNumber"
                type="text"
                placeholder="Enter phone number"
                onKeyDown={(e) => isNotNumericKey(e.key) && e.preventDefault()}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobileNumber}
                isInvalid={
                  formik.touched.mobileNumber &&
                  Boolean(formik.errors.mobileNumber)
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.mobileNumber}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <button
            id="profileBtn"
            type="submit"
          >
            Update Changes
          </button>
        </Form>
      </div>

      {/* other profile */}
      <div className="others">
        <p className="title">Other Settings</p>

        <div className="options">
          <div className="history" onClick={() => navigate(routes.history)}>
            <div className="textDiv">
              <i className="icon bi bi-bell"></i>
              <p>History</p>
            </div>
            <div>
              <i className="bi bi-chevron-right"></i>
            </div>
          </div>

          <div className="policy" onClick={() => navigate(routes.policy)}>
            <div className="textDiv">
              <i className="icon bi bi-receipt"></i>
              <p>Privacy Policy</p>
            </div>
            <div>
              <i className="bi bi-chevron-right"></i>
            </div>
          </div>

          <div className="help" onClick={() => navigate(routes.help)}>
            <div className="textDiv">
              <i className="icon bi bi-question-circle"></i>
              <p>Help</p>
            </div>
            <div>
              <i className="bi bi-chevron-right"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="menu-item" onClick={() => setIsShown(true)}>
        <i className="icon bi bi-box-arrow-right"></i>
        <p>Logout</p>
      </div>

      {/* logout modal */}
      <LogoutModal
        isShown={isShown}
        title="Please confirm"
        message="Are you sure you want to logout?"
        buttonText="Cancel"
        buttonText2="Ok"
        onHide={() => {
          setIsShown(false)
        }}
        onLogout={() => {
          clearSessionData()
          navigate(routes.login)
          setIsShown(false)
        }}
      />
      <MessageToast message={responseMessage.message} isSuccess={responseMessage.isSuccess} />
    </div>
  )
}
