import { useState } from "react"
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg"
import { FilterModal } from "../modals/FilterModal/FilterModal"
import { BidStatus } from "interfaces/Bid";
import "./TotalCount.css";

interface TotalCountProps {
  totalCount: number;
  filter: BidStatus | null;
  updateFilter: (status: BidStatus | null) => void;
}

export const TotalCount: React.FC<TotalCountProps> = ({ totalCount, filter, updateFilter }) => {
  const [isShown, setIsShown] = useState<boolean>(false)

  const handleClearFilterClick = () => {
    updateFilter(null);
  }

  return (
    <>
      <div className="totalCount">
        <p className="text">Total ({totalCount})</p>

        {/* toggle if filter applied */}
        {filter && (
          <p
            className="filterOn"
            onClick={handleClearFilterClick}
            style={{ cursor: "pointer" }}
          >
            Clear Filter
          </p>
        )}
        <button onClick={() => setIsShown(true)} className="filter">
          <FilterIcon />
        </button>
      </div>

      <FilterModal
        isShown={isShown}
        onClose={() => setIsShown(false)}
        filter={filter}
        setFilter={updateFilter}
      />
    </>
  )
}
