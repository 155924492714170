import { useState } from "react"
import { ReactComponent as Trophy } from "../../assets/icons/trophy.svg"
import "./TotalWinning.css"

export const TotalWinning: React.FC = () => {
  const [totalWinning, setTotalWinning] = useState<number>(230)
  return (
    <div className="totalWin">
      <div className="text">
        <Trophy />
        <p>Total Winning Amount:</p>
      </div>

      <div className="amount">
        <p>${totalWinning}</p>
      </div>
    </div>
  )
}
