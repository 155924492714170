import { AxiosError, AxiosResponse } from "axios";
import { LoginDTO, OtpDTO } from "interfaces/Login";
import { User, UserCreateDTO } from "interfaces/User";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { getSessionData } from "services/session.service";
import { useAuthInterceptor } from "utils/AuthInterceptorProvider";

const defaultUser: User = {
  id: "",
  mobileNumber: "",
  name: "",
  profileImage: "",
  userRole: "UNKNOWN",
  createdAt: "",
  updatedAt: "",
};

const SessionContext = React.createContext<{
  user: User;
  token: string | null;
  getUser: () => Promise<AxiosResponse<any, any> | undefined>;
  generateOTP: (data: LoginDTO) => Promise<any>;
  login: (data: OtpDTO) => Promise<any>;
  register: (data: UserCreateDTO) => Promise<any>;
}>({
  user: defaultUser,
  token: null,
  getUser: () => { throw Error("Method not implemented") },
  generateOTP: () => { throw Error("Method not implemented") },
  login: () => { throw Error("Method not implemented") },
  register: () => { throw Error("Method not implemented") },
});

interface Props {
  children: React.ReactNode;
}

export const SessionProvider: React.FC<Props> = ({ children }) => {
  const { api } = useAuthInterceptor();
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState(defaultUser);

  const generateOTP = useCallback(async (data: LoginDTO) => {
    try {
      const response = await api.post("/auth/generate-otp", data);
      return response.data.message;
    } catch (e) {
      console.log(e);
    }
  }, [api]);

  const login = useCallback(async (data: OtpDTO) => {
    try {
      const response = await api.post("/auth/verify-otp", data);
      setToken(response?.data?.data?.token);
      return response.data;
    } catch (e) {
      console.log(e);

      const error: AxiosError = e as AxiosError;

      if (error.response) {
        return Promise.reject(error.response.data);
      }
    }
  }, [api]);

  const register = useCallback(async (data: UserCreateDTO) => {
    try {
      const response = await api.post("/auth/register", data);
      return response.data;
    } catch (e) {
      console.log(e);
    }
  }, [api]);

  const getUser = useCallback(async () => {
    try {
      const url = "/user/profile";
      const response = await api.get(url);

      if (response?.data?.data) {
        setUser(response?.data?.data);
      }

      return response;
    } catch (e) {
      console.log((e as Error).message)
    }
  }, [api]);

  useEffect(() => {
    const token = getSessionData("token");

    if (token) {
      setToken(token);
      getUser();
    }
  }, [getUser]);

  return (<SessionContext.Provider value={{ user, token, getUser, generateOTP, login, register }}>{children}</SessionContext.Provider>);
};

export function useSession() {
  const sessionContext = useContext(SessionContext);

  return sessionContext;
}
