import { useEffect, useState } from "react"
import { Image } from "react-bootstrap"
import { useParams } from "react-router-dom"
import Win from "../../../assets/icons/WinThumb.svg"
import Lose from "../../../assets/icons/LoseThumb.svg"
import Carousel from "react-bootstrap/Carousel"
import { useProduct } from "providers/ProductProvider"
import { Product } from "interfaces/Product"
import { useBid } from "providers/BidProvider"
import { Bid } from "interfaces/Bid"
import { useSession } from "providers/SessionProvider"
import PlaceBid from "pages/bid/place-bid/PlaceBid"
import ProductTimer from "./ProductTimer"
import "./ProductResult.css";

const ProductResult = () => {
  const { getProductDetail } = useProduct();
  const { getBidsByProductId } = useBid();
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<Product>();
  const [productBids, setProductBids] = useState<Bid[]>([]);
  const [userBid, setUserBid] = useState<Bid>();
  const [productImages, setProductImages] = useState<string[]>([]);
  const { user } = useSession();

  useEffect(() => {
    if (!productId) {
      return;
    }

    const productDetail = getProductDetail(productId);
    getBidsByProductId(productId).then(bids => {
      if (bids) {
        setProductBids(bids);
      }
    }).catch(ex => console.log(ex));

    setProduct(productDetail);

    if (productDetail) {
      setProductImages([productDetail.image1, productDetail.image2, productDetail.image3].filter(image => typeof image === "string") as string[]);
    }
  }, [productId, getBidsByProductId, getProductDetail]);

  useEffect(() => {
    const userBid = productBids.find(bid => bid.userId === user.id);

    setUserBid(userBid);
  }, [user, productBids]);

  if (!product) {
    return <p>Product result not found !</p>
  }

  return (
    <div className="product-result">
      {/* product img carousel */}
      {productImages.length
        ? (<Carousel interval={null} controls={false}>
          {productImages.map(image => (<Carousel.Item>
            <Image src={image} />
          </Carousel.Item>))}
        </Carousel>)
        : (<p className="text-center">No Images Available</p>)
      }

      {/* product info */}
      <div className="info">
        <h4>{product.name}</h4>

        {/* details */}
        <div className="details">
          {product.details.map((detail, idx) => {
            return (
              <>
                <p key={idx}>{detail}</p>
                {idx !== product.details.length - 1 && (<div className="divider"></div>)}
              </>
            )
          })}
        </div>

        {/* desc */}
        <div className="desc">{product.description}</div>
        <ProductTimer product={product} userBid={userBid} totalBids={productBids.length} />

        {/* product Result */}
        {userBid && (<div className="product-result">
          {userBid.status === "WON" && (
            <div className="won">
              <Image src={Win} />
              <p>Congratulations you won the deal!</p>
            </div>
          )}
          {userBid.status === "LOST" && (
            <div className="lose">
              <Image src={Lose} />
              <p>You lose the deal!</p>
            </div>
          )}
        </div>)}
        <PlaceBid userBid={userBid} />
      </div>
    </div>
  )
}

export default ProductResult;
