import { useCallback, useEffect, useState } from "react";
import { routes } from "constants/routes";
import { differenceInDays, intervalToDuration, parseJSON } from "date-fns";
import { useNavigate } from "react-router-dom";
import { getImageSrc } from "utils/string.util";
import { Countdown } from "interfaces/Countdown";
import { currencyFormatter } from "utils/number.util";
import { ReactComponent as Gavel } from "assets/icons/gavel.svg";
import { Product } from "interfaces/Product";
import "./BidListItem.css";
import { formatDate } from "utils/date.util";

interface BidListItemProps {
  bidId: string;
  product: Product;
}

const BidListItem: React.FC<BidListItemProps> = ({ product, bidId }) => {
  const navigate = useNavigate();
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<Countdown>({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  function handleViewBids(bidId: string) {
    navigate(routes.placeBid(bidId))
  }

  const getCountdownString = useCallback(() => {
    if (isExpired) {
      return `Expired on ${formatDate(product.bidEndTime)}`;
    }

    return Object.entries(countdown).filter(duration => Boolean(duration[1])).reduce((acc, curr) => acc + curr[1] + curr[0][0] + " : ", "").slice(0, -3);
  }, [countdown, isExpired]);

  useEffect(() => {
    const biddingEndDate = product.bidEndTime ? parseJSON(product.bidEndTime) : new Date();

    const countdownInterval = setInterval(() => {
      const now = new Date();

      if (now >= biddingEndDate) {
        clearInterval(countdownInterval);
        return setIsExpired(true);
      }

      const duration = intervalToDuration({ start: now, end: biddingEndDate });
      const daysLeft = differenceInDays(biddingEndDate, now);

      setCountdown(prevState => ({
        ...prevState,
        days: daysLeft,
        hours: duration.hours || 0,
        minutes: duration.minutes || 0,
        seconds: duration.seconds || 0
      }));

    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [product]);

  return (
    <div className="bid-item">
      <div>
        <img src={getImageSrc(product.image1)} alt={product.name} />
      </div>
      <div className="bid-item-detail">
        <div>
          <h5>{product.name}</h5>
          <p className={["countdown", isExpired ? "expired" : ""].join(" ")}>
            {getCountdownString()}
          </p>
        </div>
        <div className="bid-section">
          <div>
            <p className="highest-bid">
              {currencyFormatter.format(0)}
            </p>
            <p className="highest-bid-label">
              Highest Bid
            </p>
          </div>
          <button type="button" onClick={() => handleViewBids(bidId)}>
            <Gavel />
            <span>Place Bid</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default BidListItem
