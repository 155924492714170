import { routes } from "./routes"

import { Image } from "react-bootstrap"
import BackButton from "components/buttons/back-button/BackButton"

import logoImage from "assets/icons/logo.svg"

export const headers = new Map([
  [
    routes.home,
    {
      startSlot: <Image src={logoImage} />,
      title: "Auction Masters",
      endSlot: null,
    },
  ],
  [
    routes.login,
    {
      startSlot: <Image src={logoImage} />,
      title: "Login",
      endSlot: null,
    },
  ],
  [
    routes.otp,
    {
      startSlot: null,
      title: "",
      endSlot: null,
    },
  ],
  [
    routes.profile,
    {
      startSlot: <Image src={logoImage} />,
      title: "Auction Masters",
      endSlot: null,
    },
  ],
  [
    routes.productsList,
    {
      startSlot: <BackButton />,
      title: "Products",
      endSlot: null,
    },
  ],
  [
    routes.productBids("*"),
    {
      startSlot: <BackButton />,
      title: "Detail",
      endSlot: null,
    },
  ],
  [
    routes.placeBid("*"),
    {
      startSlot: <BackButton />,
      title: "Place Bid",
      endSlot: null,
    },
  ],
  [
    routes.bids,
    {
      startSlot: <BackButton />,
      title: "Bids",
      endSlot: null,
    },
  ],
  [
    routes.history,
    {
      startSlot: <BackButton />,
      title: "History",
      endSlot: null,
    },
  ],
  [
    routes.addProduct,
    {
      startSlot: <BackButton />,
      title: "Add Product",
      endSlot: null,
    },
  ],
  [
    routes.editProduct("*"),
    {
      startSlot: <BackButton />,
      title: "Edit Product",
      endSlot: null,
    },
  ],
  [
    routes.policy,
    {
      startSlot: <BackButton />,
      title: "Policy",
      endSlot: null,
    },
  ],
  [
    routes.help,
    {
      startSlot: <BackButton />,
      title: "Help",
      endSlot: null,
    },
  ],
])
